import "../styles/FlipCard.css"
import frontCard from "../images/card-front.jpg"
import backCard from "../images/card-back.jpg"

const FlipCard = () => {

    return(
        <div className="flip-card">
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <img src={frontCard} alt="frontCard" className="smallerCard" />
                </div>
                <div className="flip-card-back">
                    <img src={backCard} alt="frontCard" className="smallerCard" />
                </div>
            </div>
        </div>
    )


}

export default FlipCard;
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from './pages/Homepage';
import Navigation from './components/Navigation';
import About from './pages/About';

export default function App() {
  return (
    <BrowserRouter>
    <Navigation/>
      <Routes>
        {/* <Route index element={<Homepage />}/> */}
        <Route index element={<About />}/>
      </Routes>
    </BrowserRouter>
  );
}



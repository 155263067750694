import * as React from 'react';
import '../styles/Search.css';
import "../styles/Discount.css"
import algoliasearch from 'algoliasearch/lite';
import { useStats } from 'react-instantsearch';
import { InstantSearch, SearchBox, Hits, RefinementList } from 'react-instantsearch';


const ColoredDiscount = (num) => {
  if (num === '20%') {
    return(<div className='hitDiscount ribbon ribbon--red'>{num}</div>)
  }
  if (num === '30%') {
    return(<div className='hitDiscount ribbon ribbon--orange'>{num}</div>)
  }
  if (num === '40%') {
    return(<div className='hitDiscount ribbon ribbon--yellow'>{num}</div>)
  }
  if (num === '50%') {
    return(<div className='hitDiscount ribbon ribbon--green'>{num}</div>)
  }
  if (num === '60%') {
    return(<div className='hitDiscount ribbon ribbon--blue'>{num}</div>)
  }
  if (num === '70%') {
    return(<div className='hitDiscount ribbon ribbon--purple'>{num}</div>)
  }
  if (num === '80%') {
    return(<div className='hitDiscount ribbon ribbon--pink'>{num}</div>)
  }
  if (num === '90%') {
    return(<div className='hitDiscount ribbon ribbon--silver'>{num}</div>)
  }
  if (num === '100%') {
    return(<div className='hitDiscount ribbon ribbon--gold'>{num}</div>)
  }
}


export const Hit = ({ hit }) => {
  return (
      <div className='center'>
        <a href={'https://'+hit.link}>
        {ColoredDiscount(hit.discount)}
          <div className='hitName'>{hit.name}</div>
          <div className='hitDescription'>{hit.description}</div>
          <div className='hitAddress'>כתובת: {hit.address}</div>
          <div className='hitPhone'>צרו קשר: {hit.phone}&nbsp;&nbsp;&nbsp;{hit.link}</div>
        </a>
      </div>
  );
}

  function CustomStats() {
    const {nbHits } = useStats();  
    return <>תוצאות: {nbHits}</>;
  }

export const searchClient = algoliasearch('4BG87XUSWJ', 'b1c61e5787a75647a57e4821f47e9801');

const Search = () => {

    const [open, setOpen] = React.useState(false);
    const [bgBlue, bgBlueClicked] = React.useState(false);
      const handleOpen = () => {
        setOpen(!open);
        bgBlueClicked(!bgBlue);
      };
      return (
        <div >
            <div className='container fw800 title boldFont'>הנחות לתושבי העוטף</div>
            <InstantSearch searchClient={searchClient} indexName="asakim">
        <div className='container-header'>
            <SearchBox  
                placeholder=" חפשו כאן" 
                resetIconComponent={({ classNames }) => (<div className={classNames.resetIcon}></div>)}
                submitIconComponent={({ classNames }) => (<div className={classNames.submitIcon}></div>)}
              />
            <div className='stats'> <CustomStats /> </div>       
        </div>
       
      <button  id="focusSearchBtn" className={bgBlue ? 'bgBlueClicked' : 'bgBlue'} onClick={handleOpen}>
            <span className='regularFont'> מיקוד החיפוש </span>
            <span className="dropdown-arrow" />
      </button>

      {open ? 
    <div className='focusSearchCard'> 
      <div className='unit'>
          <div className='panel-header'>
            <span>קטגוריה</span>
          </div>
            <RefinementList attribute="category" showMore={true}/>
          </div>
          <div className='ais-Panel-body'>
      </div>
      <div className='unit'>
          <div className='panel-header'>
            <span>מקום</span>
          </div>
          <div className='ais-Panel-body'>
            <RefinementList attribute="place" />
          </div>
      </div>
         
      </div>
     : <div></div>}

      <div className="container-search"> 
        <div className='results'> 
    
          <div className='hits'>    
            <Hits hitComponent={Hit}/>
          </div> 
      
        </div>
      </div>

    </InstantSearch> 
  </div>
  );
}



export default Search;
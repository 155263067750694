import "../styles/MobileMenu.css"
import logo from '../images/card.jpg'

const MobileMenu = () => {
    
    return(
    
    <div className="container1">

        {/* <input id="toggle" type="checkbox" />
        <label className="toggle-container" htmlFor="toggle">
        <span className="button button-toggle"></span>
        </label> */}

        {/* <nav className="nav">
        {/* <a className="nav-item" href="/">הנחות&nbsp;&nbsp;</a> 
        <a className="nav-item" href="/about">אודות</a>
        <div className="nav-item" />
        <div className="nav-item" />
        <div className="nav-item" />
        
        </nav> */}
        
        {/* <a href="/"> */}
            <img src={logo} alt="" id="logoMobile" />
        {/* </a> */}
  </div>
    )
}

export default MobileMenu;
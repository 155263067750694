import logo from '../images/card.jpg'
import '../styles/Navigation.css'
import MobileMenu from './MobileMenu';



const Navigation = () => {

return (
  <div>
  <MobileMenu />
<header className="header column">
  <div>
    {/* <a href="/"> */}
      <img src={logo} alt="" id="logo" />
    {/* </a> */}
    <ul>  
      {/* <li><a href="/about" id="/about">אודות</a></li> */}
      {/* <li><a href="/" id='/'>הנחות</a></li>  */}
    </ul>
  </div>

</header>
</div>
)

}

export default Navigation;
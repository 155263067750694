import FlipCard from "../components/FlipCard";
import "../index.css";


const About = () => {
    return (
        <div className="container">
          <div className='fw800 title boldFont'>
            אודות המועדון - תומכים בתושבי העוטף
        </div>
          <div className='paragraph fw200'>
            במועדון הלאומי לתושבי העוטף אנו מאמינים בכוחה של הקהילה לסייע במצבי משבר.
            האסון שאירע ב-7/10 הותיר אנשים רבים ומשפחות מיישובי העוטף ללא קורת גג, עבודה או רכוש.
            היוזמה שלנו מתמקדת ביצירת פיתרון מוחשי: כרטיס מועדון שמקנה הנחות ברחבי המדינה לנפגעי האסון.
            המשימה העיקרית שלנו היא להקל על נטל הפרנסה של תושבי העוטף ולעזור להם
            <b> לבנות מחדש את חייהם</b>.    
            <br/><br/>
            <FlipCard />
            <br/>
            <u><b>איך זה עובד? </b></u><br/>
            אנחנו יוצרים שיתופי פעולה עם בתי עסק בתחומים שונים כגון מזון, ביגוד, מוצרי חשמל ועוד.
            חברי המועדון יכולים להציג את הכרטיס במעמד הקנייה כדי להשתמש בהנחות שבית העסק מציע.
            <b> אנחנו מתחייבים </b>
            להמשיך לחפש עוד שותפויות והזדמנויות להרחבת רשת העסקים שלנו, כדי להבטיח שבעלי כרטיס המועדון יוכלו לקבל היצע רחב של הנחות והטבות.
            <br/><br/>
            <u><b>הרשמה לכרטיס המועדון </b></u><br/>
            תושבי העוטף זכאים לקבל את הכרטיס בדואר ללא כל עלות. 
            ניתן להירשם לקבלת הכרטיס על ידי מילוי טופס ההרשמה שלנו
            <a href="https://forms.gle/9S3s1Zf1XDAdQgnAA" target="_blank" rel="noreferrer"> <u className="linked">כאן</u>.</a>
            <br/><br/>
            <u><b>הרשמה לבעלי עסקים </b></u><br/>
            אנחנו מזמינים בעלי עסקים להיות שותפים למיזם ולתת הנחות למחזיקי כרטיס מועדון.
            ניתן להצטרף לרשת העסקים שלנו
            <a href="https://forms.gle/qNTbab7eqxriwoDeA" target="_blank" rel="noreferrer"> <u className="linked">כאן</u>.</a>
            <br/><br/>
            <u><b>תמיכה כספית </b></u><br/>
            המועדון הלאומי לתושבי העוטף מתקיים בזכות נדיבות הלב של אזרחי ישראל.
            התרומות שאנחנו מקבלים ממלאות תפקיד מכריע בסיוע למיזם ובעזרה למשפחות העוטף לבנות מחדש את חייהם.
            ניתן לתרום למועדון באופן הבא:<br/>
            1. <a href="https://payboxapp.page.link/K4VCfFcCa1s34hnz9" target="_blank" rel="noreferrer" className="linked">פייבוקס</a>
            <br/>
            2. <a href="https://paypal.me/hamoadonhaleumi" target="_blank" rel="noreferrer" className="linked">פייפל</a>
          
            <br/><br/>
            <b>ביחד, נעשה את השינוי. </b>
            אנחנו עומדים מאוחדים - המועדון, בתי העסק והאזרחים - ומהווים קרן של תקווה בימים קשים אלו.
            מעורבות של כל אחד מאיתנו, בין אם בעידוד הרשמה לכרטיס המועדון, שותפות ברשת העסקים או במתן תרומה, היא חשובה לאין ערוך ביצירת שינוי מוחשי בחייהם של הנפגעים.
            אנחנו אסירי תודה על התמיכה הבלתי מעורערת שקיבלנו עד כה מצד אנשים, בתי עסק וקהילות. 
            רוח ההתנדבות ושיתוף הפעולה בין כולם היא הכוח המניע מאחורי המאמצים שלנו. 
            יחד, נוכל לסלול את הדרך לעתיד מזהיר יותר עבור תושבי העוטף.
            <br/><br/>
            ליצירת קשר 
            <b> (בוואטסאפ בלבד) </b>
            ניתן לשלוח הודעה למספר:
            052-2357054.
            <br/><br/>
          </div>
        </div>
  );
}

export default About;